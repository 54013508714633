import { createTheme, ThemeOptions } from '@mui/material';

const fontFamily = 'Inter'

/**
 * Material UI theme configuration
 */
const defaultTheme: ThemeOptions = createTheme({
  palette: {
    primary: {
      main: '#006450',
      light: '#6e6e74',
      dark: '#8b8b9b',
    },
    secondary: {
      main: '#2b3d3d',
    },
    text: {
      primary: 'rgba(0,0,0,0.78)',
      secondary: '#6e6e74',
      disabled: '#8b8b90',
    },
  },
  typography: {
    h1: {
      fontFamily: `${fontFamily}, "Helvetica", "Arial", sans-serif`,
      fontSize: 98,
    },
    h2: {
      fontFamily: `${fontFamily}, "Helvetica", "Arial", sans-serif`,
      fontSize: 48,
    },
    h3: {
      fontFamily: `${fontFamily}, "Helvetica", "Arial", sans-serif`,
      fontSize: 24,
    },
    h4: {
      fontFamily: `${fontFamily}, "Helvetica", "Arial", sans-serif`,
      fontSize: 20,
    },
    h5: {
      fontSize: 18,
    },
    h6: {
      fontFamily: `${fontFamily}, "Helvetica", "Arial", sans-serif`,
      fontSize: 16,
    },
    subtitle1: {
      fontFamily:`${fontFamily}, "Helvetica", "Arial", sans-serif`,
      fontSize: 14,
    },
    subtitle2: {
      fontFamily: `${fontFamily}, "Helvetica", "Arial", sans-serif`,
      fontSize: 14,
    },
    body1: {
      fontFamily: `${fontFamily}, "Helvetica", "Arial", sans-serif`,
      fontSize: 14,
    },
    body2: {
      fontFamily: `${fontFamily}, "Helvetica", "Arial", sans-serif`,
      fontSize: 14,
    },
    button: {
      fontFamily: `${fontFamily}, "Helvetica", "Arial", sans-serif`,
      fontSize: 14,
    },
    caption: {
      fontFamily: `${fontFamily}, "Helvetica", "Arial", sans-serif`,
      fontSize: 12,
    },
    overline: {
      fontSize: 12,
    },
  },
});

export default defaultTheme;
