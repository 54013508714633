import { Box, LinearProgress, Typography } from "@mui/material";
import { IProgressbarWithMessage } from "./interfaces/IProgressbarWithMessage.interface";

/**
 * Renders a progressbar with a message
 * @returns 
 */
const ProgressbarWithMessage = (props: IProgressbarWithMessage): JSX.Element => {
    const { msg } = props;

    return (
        <Box>
            <Typography sx={{ mb: 1 }}>{msg}</Typography>
            <LinearProgress />
        </Box>
    );
}

export default ProgressbarWithMessage;