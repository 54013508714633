import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import NL from '../translations/nl.json'

const resources = {
  nl: NL,
}

i18n.use(initReactI18next).init({
  resources,
  defaultNS: 'app',
  fallbackLng: 'nl',
  returnNull: false,
  react: { useSuspense: false },
})

export default i18n
