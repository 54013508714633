import { createMachine, assign } from 'xstate';
import { IViewerTree } from '../interfaces/IViewerTree.interface';
import { IAuthenticateResponse, IViewerMetaData } from '../interfaces/IAuthenticateResponse.interface';

const initializeViewerMachine = createMachine({
  /** @xstate-layout N4IgpgJg5mDOIC5QEsB2yAuyCGAbZAXmAGrJgDuYATgLLYDGAFmmAHQDCjY9A1mlAAIAZgHsqAjCJ5hUAYggjUbNADcpy9FjyESZSrQbMlHLr37CxE9agSqR9bFkUBtAAwBdN+8SgADiNhMZEUfEAAPRAAOABZXVgAmaIBmAEYUpPiAVgBOSIB2ADYCgBoQAE9EaIL41nT4tKTspOis+NcCgF8O0rQg7SJSCmo6JhYTbj5UQVFxSWk5aioxVl9cRxmAW1ZerXwBvWHDMc4J8xmredtUNQcnVC8vUP9Au9CIhAy411cU6MjE1x5VzZNKZUoVBBFAqsbJVVyNFJteJtTrdEA7HB7XRDAyjYwAQQArhguKgsLd+PJFBo1NJtppMTpBvoRkY2ESSTJyY5+FcbjyXB5HkgQM8giERe8kplIqxMtVoikZf9YZkUuDEGq8qwCsDotlCrC8vFItkuj0Gf1sSyjgTiaTuVgplTjHY6RirczDnj2faucgKVM+fYBfchSlvCKxa9JYgALRtFKsPJJFNqpJJXX5bIahDy7K1dryn7RTLfGXm9GWrFe3Fs1gch0BnnOxbLVbrMRbD01g51saN-2BqDBimCzweJ4BcWoN6a+K5lLwpOp+KpvLGlK61yZSs9pl91kDv1k5t3CxULYAZUYInIAl82FgsHIYggAjQvmJsgACk+X2+H6oF+GACEw2BTJAwp+NOMagO8cYGjqOS-JkLT1PqiKLikBbwgaMSuFk1R5L8e7VgeOJHnanKnmOqAXtet73o+z6vlQ76ft+V6EgARhsmACNgJ6OsE9GbNBoqwaJc4fLkCTNBmipAskBTNNhuGNBuO7pAU0R-CkZF9L2lG2mwN53gIbbiBscCwNgMAumwsAYI4GhGRRNo+qw5n3lZAg2c+9lgBJ0bSbGeYyqw-x5E0LTrtEeRguUiBLkhiKJGq3wJfkSSGbsHnevWABiaB4I5rDOa59LufsJleSVqB4CFUkSvBiCacmqqRDlqm6guyUIN1SbwjESp6SqO5dGiqAiBAcChPutWeWyU4vGFbUIAm+rJqm0rpJmrjZrmcaZJkMJ9VksWnUk+SohaNXWoVxymJM0yWHMMirTOMkFJF6XNMCiX-HpuaJTU6Q-IR12NKmeWMktT3UU2w5fXB4SatUcp9TFqmNKdSUQvEhSsPq8qRO02SHQaeRw56h6mQ2wlnqJDGo+t6ObahyEgqW6G-CC-UQqkcqaSmv2mimeSRHdVYPbWVG+jRImKAx3lMQ+-5sRxwHEmzrUc3GMSFnpv0YQargJbm9QFtk2QmnbiKqbk8S08Zy1jD5llUEs1m2UFeuzuFa7apkqTxAUkS-Cat2ROpZ2AqdCUkTdG6uwV-bGA1eABzJipxONeSKuTO6U9Ei7wiHkSZETqoJXbNNTUAA */
  id: "initializeViewerMachine",
  initial: "Checking for token",
  tsTypes: {} as import("./initializeViewer.machine.typegen").Typegen0,
  context: {
    viewer: { 
      tree: undefined, 
      meta: undefined, 
      objectvalue: "" 
    },
    form: {
      token: "", 
      password: "", 
      submittedTimes: 0 
    },
    errorMsg: "",
  },
  schema: {
    context: {} as {
      form: { 
        token: string, 
        password: string, 
        submittedTimes: number 
      }
      viewer: { 
        tree: IViewerTree | undefined, 
        meta: IViewerMetaData | undefined, 
        objectvalue: string 
      }
      errorMsg: string
    },
    services: {} as {
      isTokenPresent: {
        data: boolean
      },
      authenticate: {
        data: IAuthenticateResponse
      },
    },
    events: {} as {
      type: "Password is required or invalid"
    } | {
      type: "Password input changed",
      value: string
    } | {
      type: "Submit authentication form"
    }
  },
  states: {
    "Checking for token": {
      invoke: {
        src: 'isTokenPresent',
        onDone: [{
          target: "Authenticating",
          actions: assign((context, event) => ({
            form: { 
              ...context.form, 
              token: new URL(window.location.href).searchParams.get('token') || "" 
            }
          }))
        }],
        onError: [{
          target: "Show error message",
          actions: assign((context, event) => ({
            errorMsg: event.data,
          }))
        }]
      }
    },
    Authenticating: {
      invoke: {
        src: 'authenticate',
        onDone: [{
          target: "Final",
          cond: "Password is not required or valid",
          actions: assign((context, event) => ({
            viewer: {
              ...context.viewer,
              meta: event.data.viewerMetaData,
              tree: event.data.tree
            },
            errorMsg: ""
          }))
        }, {
          target: "Authentication form",
          cond: "Password is required or invalid",
        }],
        onError: [{
          target: "Show error message",
          actions: assign((context, event) => ({
            errorMsg: event.data,
          }))
        }]
      }
    },

    "Authentication form": {
      states: {
        "Show password input": {
          on: {
            "Password input changed": {
              target: "Show password input",
              internal: true,
              actions: assign((context, event) => ({
                form: { 
                  ...context.form, 
                  password: event.value
                },
                errorMsg: ""
              }))
            },
            "Submit authentication form": {
              target: "#initializeViewerMachine.Authenticating",
              actions: assign((context, event) => ({
                form: { 
                  ...context.form, 
                  submittedTimes: context.form.submittedTimes + 1,
                },
                errorMsg: ""
              }))
            }
          }
        }
      },

      initial: "Show password input"
    },

    "Show error message": {
      onDone: [{
        actions: assign((context, event) => ({
          errorMsg: ""
        }))
      }],
    },

    "Final": {
      type: "final",
      onDone: [{
        actions: assign((context, event) => ({
          errorMsg: ""
        }))
      }],
    }
  },
}, {
  guards: {
    "Password is required or invalid": (context, event) => {
      return !event.data.canView;
    },
    "Password is not required or valid": (context, event) => {
      return event.data.canView;
    }
  }
});

export default initializeViewerMachine;
