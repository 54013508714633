import { Box, Typography } from '@mui/material'
import { Container } from '@mui/system'
import { useTranslation } from 'react-i18next'
import { xx_logo } from "@xxllnc/xxllnc-ui";

/**
 * Renders the primary header component
 * @returns the primary header
 */

// Register imported UI logo
new xx_logo();
declare global {
  namespace JSX {
    interface IntrinsicElements {
      'xx-logo': { team: string }
    }
  }
}
const PrimaryHeader = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <>
      <Box component={'div'} sx={{ py: 3 }}>
        <Container>
            <xx-logo team="productiviteit_od"></xx-logo>
            {/* <Typography variant={'h3'} sx={{ color: 'primary.main' }}>
                {t('PROJECT_NAME')}
            </Typography> */}
          <Typography variant={'h6'} sx={{ color: 'primary.main', fontWeight: 'normal' }}>
                {t('PROJECT_SLOGAN')}
            </Typography>
        </Container>
      </Box>
    </>
  )
}

export default PrimaryHeader;
