import { useEffect, useRef } from "react";
import { IViewerTree } from "../../interfaces/IViewerTree.interface";
import { InteractionMode, StaticTreeDataProvider, Tree, TreeItem, UncontrolledTreeEnvironment, TreeEnvironmentRef, TreeRef } from "react-complex-tree";
import { service } from "../../service";
import 'react-complex-tree/lib/style-modern.css';

/**
 * Renders a tree that renders the article nodes for the viewer
 * @returns - A viewer tree object
 */
const ViewerTree = (props: IViewerTree): JSX.Element => {
  const treeItems = service.normalizeTreeItems(props);
  const dataProvider = new StaticTreeDataProvider(treeItems);
  const treeEnvironment = useRef<TreeEnvironmentRef>(null);
  const tree = useRef<TreeRef>(null);

  /**
   * On load, select the first chapter
   */
  useEffect(() => {
    const interval = setTimeout(() => {
      if (treeEnvironment.current && tree.current) {        
        const linearItems = tree.current.treeContext.getItemsLinearly();
        const focusItem =
          treeEnvironment.current.viewState[tree.current.treeId]!.focusedItem ??
          linearItems[0].item;

        if (
          !treeEnvironment.current.viewState[
            tree.current.treeId
          ]!.expandedItems?.includes(focusItem)
        ) {
          tree.current.expandItem(focusItem);
          return;
        }

        tree.current.moveFocusDown();
      }
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  /**
   * Return
   */
  return (
    <>
    <style>{`
        :root {
          --rct-bar-color: #006450;
          --rct-item-height: fit-content;
          --rct-item-margin: 5px;
          --rct-color-focustree-item-selected-bg: #006450;
          --rct-color-focustree-item-selected-text: white;
          --rct-color-nonfocustree-item-selected-text: white;
          --rct-color-arrow: currentColor;
          }
        .rct-tree-item-button {
          padding: 5px var(--rct-item-padding) 5px calc(var(--rct-item-padding) + var(--rct-arrow-container-size) + var(--rct-arrow-padding));
          word-break: break-word;
        }      
      `}</style>
      <UncontrolledTreeEnvironment
        ref={treeEnvironment}
        dataProvider={dataProvider}
        getItemTitle={item => item.data.title}
        viewState={{}}
        onFocusItem={(item: TreeItem) => {
          const section = document.querySelector(`#${(service.normalizeItemIndex({ 
            index: item.index 
          }))}`);
          if (section) {
            section.scrollIntoView({ behavior: 'smooth', block: 'start' });
          }
        }}
        defaultInteractionMode={{
          mode: 'custom',
          extends: InteractionMode.ClickItemToExpand,
          createInteractiveElementProps: (item, treeId, actions, renderFlags) => ({
            /**
             * 1. avoid multi select
             * 2. (will not work as isFocused is always true, ignore for now) focus on first click and select item if it's focused (second click)
             * 3. if has children then toggle expand/collapse
             */
            onClick: (event) => {
              if (item.children && item.children.length > 0) {
                actions.toggleExpandedState();
              }
              if (!renderFlags.isFocused) {
                actions.focusItem();
              }
              else { 
                actions.selectItem() 
              };
            },
            onFocus: (event) => {
              actions.focusItem();
            },
          }),
        }}
      >
        <Tree treeId="viewer-tree" rootItem="root" ref={tree}/>
      </UncontrolledTreeEnvironment>
    </>
  );
}

export default ViewerTree;
