/**
 * Levelid values
 */
export const LEVEL_IDS = {
    LICHAAM: "0",
    REGELS: "A1",
    HOOFDSTUK: "A2",
    AFDELING: "A4",
    ARTIKEL: "A8",
    LID: "A9",
    PARAGRAAF: "A5",
    SUBPARAGRAAF: "A6",
    SUBSUBPARAGRAAF: "A7",
    TITEL: "A3",
    BIJLAGE_ROOT: "B1",
    BIJLAGE_CHILDREN: "B2",
    TOELICHTING: "C1",
    ALGEMENE_TOELICHTING_PARENT: "CX",
    ALGEMENE_TOELICHTING_CHILDREN: "CC",
    ARTIKELSGEWIJZE_TOELICHTING_ROOT: "CY",
    ARTIKELSGEWIJZE_TOELICHTING_CHILDREN: "C8",
    TOELICHTING_ARTIKEL: "C3",
};
