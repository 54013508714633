import React, { useEffect, useRef } from 'react';
import Map from 'ol/Map';
import View from 'ol/View';
import TileLayer from 'ol/layer/Tile';
import 'ol/ol.css';
import WMTS from 'ol/source/WMTS';
import TileGrid from 'ol/tilegrid/WMTS';
import { addProjection, Projection } from 'ol/proj';
import { useMapStore } from '../../stores/MapState.store';
import { Extent } from 'ol/extent';

// Define custom projection and extent
const extent: Extent = [-285401.92, 22598.08, 595401.92, 903401.92];
const resolutions: number[] = [
  3440.64, 1720.32, 860.16, 430.08, 215.04, 107.52, 53.76, 26.88, 13.44, 6.72,
  3.36, 1.68, 0.84, 0.42, 0.21, 0.105, 0.0525,
];

const projection: Projection = new Projection({
  code: 'EPSG:28992',
  units: 'm',
  extent: extent,
});

// Add projection to OpenLayers
addProjection(projection);


const matrixIds = resolutions.map((_, index) => index.toString());

const OpenLayersMap: React.FC = () => {
  const mapRef = useRef<HTMLDivElement | null>(null);
  const { setBaseLayer, setMap } = useMapStore();
  // const setBaseLayer = useMapStore((state): object => state.setBaseLayer);

  useEffect(() => {
    if (!mapRef.current) return;

    const baseLayer = new TileLayer({
      source: new WMTS({
        url: 'https://service.pdok.nl/brt/achtergrondkaart/wmts/v2_0?',
        layer: 'standaard',
        matrixSet: 'EPSG:28992',
        format: 'image/png',
        projection: projection,
        tileGrid: new TileGrid({
          origin: [-285401.92, 903401.92], // Top-left corner of the extent
          resolutions: resolutions,
          matrixIds: matrixIds,
        }),
        style: 'default',
        wrapX: true,
      }),
    });

    // Create a new OpenLayers map

    const map = new Map({
      target: mapRef.current,
      layers: [baseLayer],
      view: new View({
        projection: projection,
        center: [154500, 463500], // Center within the extent
        zoom: 3,
        resolutions: resolutions,
        extent: extent,
      }),
    });
   

    // Store map instance in Zustand store
    setMap(map);
    setBaseLayer(baseLayer);

    return () => {
      map.setTarget('');
    };
  }, [setMap, setBaseLayer]);

  return <div ref={mapRef} style={{ width: '100%', height: '100%' }}></div>;
};

export default OpenLayersMap;
