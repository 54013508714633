import { Alert, Box, Button, TextField } from "@mui/material";
import { IAuthenticationForm } from "./interfaces/IAuthenticationForm.interface";
import { useTranslation } from "react-i18next";
import LinearProgress from '@mui/material/LinearProgress';

/**
 * Renders a form that is used to initialize the viewer component
 * @returns 
 */
const AuthenticationForm = (props: IAuthenticationForm): JSX.Element => {
    const { t } = useTranslation();
    const { submittedTimes, onSubmit, onPasswordInputChange, isLoading } = props;
    const title = submittedTimes !== 0
      ? t('ERRORS.WRONG_PASSWORD_TO_VIEW_DECISION')
      : t('ERRORS.PASSWORD_REQUIRED_TO_VIEW_DECISION');
    return (
        <>
        {props.isLoading ? (
          <Box sx={{ width: '400px' }}>
            <LinearProgress sx={{color: 'text.primary'}}/>
            <Alert severity="info">
              {t('AUTHENTICATION_FORM.LOADING')}
            </Alert>
          </Box>
        ) : (
          
          <Box 
          component="form" 
          onSubmit={(event) => onSubmit(event)}
        >
          <Alert severity={submittedTimes !== 0 ? "error" : "info"}>
            {title}
          </Alert>
          <TextField
            error={submittedTimes !== 0}
            label={t('AUTHENTICATION_FORM.PASSWORD_INPUT_LABEL')}
            type="password" 
            sx={{ width: '100%', mt: 2, mb: 1, border: 'red' }} 
            onChange={(event) => onPasswordInputChange(event)} 
          />
          <Button 
            disabled={isLoading}
            type="submit" 
            variant="contained">
              {t('AUTHENTICATION_FORM.SUBMIT_BUTTON_TEXT')}
          </Button>
        </Box>
        )}
        </>
    );
}

export default AuthenticationForm;