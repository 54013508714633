import { Box, Typography } from '@mui/material'
import { Container } from '@mui/system'
import { DescriptionOutlined } from '@mui/icons-material'
import { IPlanHeader } from './interfaces/IPlanHeader.interface'

/**
 * Renders the plan header
 * @returns the header thats shown after the primary header
 */
const PlanHeader = (props: IPlanHeader): JSX.Element => {
    const { title } = props;

    return (
        <Box sx={{ bgcolor: 'background.paper'}}>
            <Container>
                <Box py={5} sx={{ display: 'flex' }}>
                    <DescriptionOutlined color={'primary'} sx={{ fontSize: 'h2', mr: 1 }} />
                    <Typography component={'h2'} sx={{ fontSize: 16 }}>
                        {title}
                    </Typography>
                </Box>
            </Container>
        </Box>
  )
}

export default PlanHeader;
