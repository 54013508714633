import ImageLayer from "ol/layer/Image";
import { create } from "zustand";
import { IMapState } from "../interfaces/IMapState.interface";


//Create a Zustand store to manage the WMS layer URL.
export const useMapStore = create<IMapState>((set) => ({
  map: null,
  wmsLayerUrl: '',
  baseLayer: null,
  setMap: (map) => set({ map: map }),
  setBaseLayer: (layer) => set({ baseLayer: layer }),
  setWmsLayerUrl: (url) => set({ wmsLayerUrl: url }),
  addMapLayer: (layer) =>
    set((state) => {
      state.map?.addLayer(layer);
      return { map: state.map }; // Returning the modified state
    }),
  removeLayers: () =>
    set((state) => {
      state.map?.getLayers().getArray().forEach(layer => {
        if (layer instanceof ImageLayer) {
          state.map?.removeLayer(layer);
        }
      });
      return { map: state.map };
    }),
}));
